import React, { useState, useEffect, useCallback, useRef } from 'react';
import Box from '@mui/material/Box';
const MAX_WORDS = 5;

const removePunctuation = (text) => {
    const accentuatedChars = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ';
    const regularChars = 'AAAAAAaaaaaaOOOOOOooooooEEEEeeeeCcIIIIiiiiUUUUuuuuyNn';

    let result = text.split('').map((char) => {
        const index = accentuatedChars.indexOf(char);
        return index !== -1 ? regularChars[index] : char;
    }).join('');

    return result.replace(/[!"#&'()*+:;<=>?@[\]^_`{|}~]/g, '').replace(/(?<!\d)[.,]|[.,](?!\d)/g, '');;
};

const calculateLeftPosition = (progress, start, end) => {
    //console.log(progress)
    //console.log(((end - start) * progress)/100 + start)
    return (((end - start) * (progress)) / 100 + start);
};

const splitSubtitle = (subtitle) => {
    const words = subtitle.words;
    const segments = [];

    for (let i = 0; i < words.length; i += MAX_WORDS) {
        const segmentWords = words.slice(i, i + MAX_WORDS);
        segments.push({
            ...subtitle,
            words: segmentWords,
            transcription: segmentWords.map(w => w.word).join(' '),
            time_begin: segmentWords[0].time_begin,
            time_end: segmentWords[segmentWords.length - 1].time_end,
        });
    }
    return segments;
};


const getCurrentSubtitle = (subtitles, currentTime) => {
    for (const subtitle of subtitles) {
        const splitSubtitles = splitSubtitle(subtitle);
        for (const split of splitSubtitles) {
            if (currentTime >= split.time_begin && currentTime <= split.time_end) {
                return split;
            }
        }
    }
    return null;
};
export default function VideoPreview({
    subtitles,
    subPosition,
    subStyleSelected,
    selectHighlight,
    videoPreviewUrl,
    subSize }) {
    const videoRef = useRef(null);
    const [currentTime, setCurrentTime] = useState(0);
    const [progress, setProgress] = useState(0);
    const animationFrameId = useRef(null); // to hold our animation frame ID
    const trendySub1 = {
        height: '100%',
        fontFamily: 'The Bold Font',
        textTransform: "uppercase",
        textAlign: "center",
        justifyContent: "center",
        fontSize: `${0.1875 * subSize}px`,
        marginLeft: "auto",
        marginRight: "auto",
        textShadow: ` -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 2px 3px 4px #000,  0 0 5px #000`,
    }
    const trendySub2 = {
        height: '100%',
        fontFamily: 'The Bold Font',
        textTransform: "uppercase",
        justifyContent: "center",
        textAlign: "center",
        fontSize: `${0.1875 * subSize}px`,
        marginLeft: "auto",
        marginRight: "auto",
        textShadow: `0 0 8px #000,0 0 9px #000,0 0 10px #000,0 0 11px #000,0 0 12px #000`,
    }
    const cleanSub = {
        height: '100%',
        fontFamily: 'Comfortaa',
        textAlign: "center",
        fontSize: `${0.1875 * subSize}px`,
    }
    const simpleSub = {
        height: '100%',
        fontFamily: 'Helvetica Neue',
        textAlign: "center",
        fontSize: `${0.1875 * subSize}px`,
    }


    let subMargin = `${100 - subPosition}%`;

    let subStyle
    switch (subStyleSelected) {
        case 'trendy1':
            subStyle = trendySub1;
            break;
        case 'trendy2':
            subStyle = trendySub2;
            break;
        case 'clean':
            subStyle = cleanSub;
            break;
        case 'simple':
            subStyle = simpleSub;
            break;
    }

    const handleTimeUpdate = () => {
        setCurrentTime(videoRef.current.currentTime);
    };

    const updateAnimation = () => {
        if (videoRef.current) {
            setCurrentTime(videoRef.current.currentTime); // Update current time
            animationFrameId.current = requestAnimationFrame(updateAnimation); // Loop the update
        }
    };

    useEffect(() => {
        updateAnimation(); // Start the loop when the component mounts

        return () => {
            if (animationFrameId.current) {
                cancelAnimationFrame(animationFrameId.current); // Cancel the loop when the component unmounts
            }
        };
    }, []);


    const currentSubtitle = getCurrentSubtitle(subtitles, currentTime);
    let isTwoLineSubtitle = false;
    if (currentSubtitle) {
        isTwoLineSubtitle = currentSubtitle.words.length === 5;
    }

    return (
        <>
            <Box style={{
                borderRadius: "10px",
                overflow: "hidden",
                position: "relative",
                display: "flex",
                justifyContent: "center",/* Centrer horizontalement */
                height: "100%"
            }}>
                <video ref={videoRef} autoPlay muted loop width="202px" height="360px" requestAnimationFrame={handleTimeUpdate}>
                    <source src={videoPreviewUrl || video} type="video/mp4" />
                    Votre navigateur ne supporte pas les vidéos HTML5.
                </video>
                <div className="subtitle" style={{
                    position: "absolute",
                    top: subMargin,
                    lineHeight: "115%",
                    ...subStyle
                }}>
                    {currentSubtitle && (
                        isTwoLineSubtitle ? (
                            <div style={{
                            }}>
                                <span style={{ color: selectHighlight && currentTime <= currentSubtitle.words[2].time_end && subStyleSelected != "clean" ? 'yellow' : 'white', fontWeight: selectHighlight && currentTime <= currentSubtitle.words[2].time_end && subStyleSelected === "clean" ? 'bold' : '' }}>
                                    {removePunctuation(currentSubtitle.words.slice(0, 3).map(word => word.word).join(' '))}
                                </span>
                                <br />
                                <span style={{ color: selectHighlight && currentTime > currentSubtitle.words[2].time_end && subStyleSelected != "clean" ? 'yellow' : 'white', fontWeight: selectHighlight && currentTime > currentSubtitle.words[2].time_end && subStyleSelected === "clean" ? 'bold' : '' }}>
                                    {removePunctuation(currentSubtitle.words.slice(3).map(word => word.word).join(' '))}
                                </span>
                            </div>
                        ) : (
                            currentSubtitle.transcription.split(' ').map((word, index) => {
                                const cleanedWord = removePunctuation(word);
                                const wordDetails = currentSubtitle.words.find(w => w.word.trim() === word.trim());

                                const isWordHighlighted = wordDetails && currentTime >= wordDetails.time_begin && currentTime <= wordDetails.time_end + 1 / 30;
                                return (
                                    <span key={index} style={{ textAlign: "center", color: selectHighlight && isWordHighlighted && subStyleSelected != "clean" ? 'yellow' : 'white', fontWeight: selectHighlight && isWordHighlighted && subStyleSelected === "clean" ? 'bold' : '' }}>                                    {cleanedWord + '  '}
                                    </span>
                                );
                            })
                        )
                    )}

                </div>
            </Box>
        </>
    )
};
