import React from "react";
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import { useTheme } from '@mui/material/styles';

const SubTypeSelector = ({
  selectedStyle,
  onSelectStyle,
}) => {
  const theme = useTheme();
  const rootStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    width: "100%"
  };

  const subtitleCardStyle = {
    width: "100%", // prend en compte l'espacement
    margin: '5px',
    padding: '15px',
    height: '60px', // ou la hauteur que vous préférez
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.9rem',
  };

  const trendySub1 = {
    height: '100%',
    fontFamily: 'The Bold Font',
    textTransform: "uppercase",
    textAlign: "center",
    PointerEvent: "none",
    fontSize: "1em",
    textShadow: ` -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 2px 3px 4px #000,  0 0 5px #000`,
  }

  const simpleSub = {
    fontFamily: "Helvetica Neue",
    fontSize: "1em",
    color: "white",
    textAlign: "center",
  }

  const selectedCardStyle = {
    backgroundColor: "#7b84f4", // ou toute autre couleur ou style que vous souhaitez appliquer
    ...subtitleCardStyle, // pour conserver les styles existants
  };


  const handleSubtitleSelect = (style) => {
    // Informer le composant parent de la sélection
    onSelectStyle(style);
  }

  return (
    <div>
      <div style={rootStyle}>

        <Card style={selectedStyle === 'trendy1' ? selectedCardStyle : subtitleCardStyle}>
          <CardActionArea style={trendySub1} onClick={() => handleSubtitleSelect('trendy1')}>
            Trendy subs
          </CardActionArea>
        </Card>
        <Card style={selectedStyle === 'simple' ? selectedCardStyle : subtitleCardStyle}>
          <CardActionArea style={simpleSub} onClick={() => handleSubtitleSelect('simple')}>
            Simple Sub
          </CardActionArea>
        </Card>
      </div>

    </div>
  );
}

export default SubTypeSelector;