import React, { useState, useEffect, useCallback, useRef } from 'react';
import Box from '@mui/material/Box';
import { useAuth0 } from "@auth0/auth0-react";
const test = false;
//const stripe = require('stripe')(process.env.REACT_APP_STRIPE_API_KEY_TEST);
const stripe = require('stripe')(process.env.REACT_APP_STRIPE_API_KEY);

export default function Pricing({ userMetadata }) {
    const { isAuthenticated, user, getAccessTokenSilently, isLoading } = useAuth0();
    console.log(userMetadata)
    const [sessionURL, setSessionURL] = useState("");
    async function handleCheckout() {
        if (test) {
            const session = await stripe.checkout.sessions.create({
                payment_method_types: ['card'],
                customer: userMetadata.stripe_customer_id,
                line_items: [{
                    price: 'price_1P2DVwFI1DSE4jbVYCmVLv2h', // Remplace cela par l'ID du prix de ton abonnement
                    quantity: 1,
                }],
                discounts
                    : [{
                        coupon
                            : 'CxPTbV90',
                    }],
                mode: 'payment',
                success_url: 'https://app.geniussub.com/?session_id={CHECKOUT_SESSION_ID}',
                cancel_url: 'https://app.geniussub.com/',
            });
            return (session.url);
        } else {
            const session = await stripe.checkout.sessions.create({
                payment_method_types: ['card'],
                customer: userMetadata.stripe_customer_id,
                line_items: [{
                    price: 'price_1P4KsMFI1DSE4jbVpUXFgvoe', // Remplace cela par l'ID du prix de ton abonnement
                    quantity: 1,
                }],
                discounts
                    : [{
                        coupon
                            : 'btD2n4gk',
                    }],
                mode: 'payment',
                success_url: 'https://app.geniussub.com/?session_id={CHECKOUT_SESSION_ID}',
                cancel_url: 'https://app.geniussub.com/',
            });
            return (session.url);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            setSessionURL(await handleCheckout());
        };
        fetchData();
    }, []);

    return (
        <Box
            style={{
                //CENTER ITEM
                display: 'flex',
                flexDirection: 'column', // Change to column
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                textAlign: 'center',
                margin: "auto"
            }}
        >
            <div>
                <h3 style={{ color: '#6366F1', fontWeight: 'bold' }}>Pricing</h3>


                <h2 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '8px' }}>Save hours of video editing, reach global audience</h2>
                <p style={{ fontSize: '0.75rem', marginTop: '0', paddingTop: '0' }} data-aos="zoom-y-out">
                    <span style={{ color: '#10B981' }}>$20 off</span> for the first 100 customers
                </p>

                <div
                    style={{
                        //CARD
                        display: 'flex',
                        flexDirection: 'column', // Change to column
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '20px',
                        width: '100%',
                        borderRadius: '0.5rem',
                    }}
                    data-aos="zoom-y-out"
                >
                    {/* Rest of the card code */}
                </div>
            </div>
            <div
                style={{
                    //CARD
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '20px',
                    width: '100%',
                    borderRadius: '0.5rem',
                }}
                data-aos="zoom-y-out"
            >
                <div
                    style={{
                        background: '#121212', // 'bg-gray-900'
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px', // 'gap-5'
                        padding: '32px', // 'p-8'
                        borderRadius: '0.5rem', // 'rounded-lg'
                        //make the card width responsive
                        width: '100%',
                        maxWidth: '500px',
                        boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
                    }}
                >
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '16px' }}>
                        <div style={{ textAlign: 'left' }}>
                            <p style={{ fontSize: '18px', fontWeight: 'bold' }}>GeniusSub Editor</p>
                        </div>
                    </div>
                    <div style={{ display: 'flex', gap: '4px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', marginBottom: '4px', fontSize: '18px' }}>
                            <p style={{ position: 'relative', opacity: '0.8' }}>
                                <span style={{ position: 'absolute', background: '#D1D5DB', height: '1.5px', left: '0', right: '0', top: '50%' }}></span>
                                <span style={{}}>{"$49"}</span>
                            </p>
                        </div>
                        <p style={{ fontSize: '40px', letterSpacing: '-0.05em', fontWeight: '800', marginTop: "10px", marginBottom: "15px" }}>${"29"}</p>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', marginBottom: '15px' }}>
                            <p style={{ fontSize: '0.75rem', opacity: '0.6', textTransform: 'uppercase', fontWeight: '600' }}>USD</p>
                        </div>
                    </div>

                    <p style={{ textAlign: 'left', lineHeight: '1.625' }}>
                        Maximize the impact of your TikTok, Instagram Reels, or YouTube Shorts. With GeniusSub, gain access to our powerful tool that effortlessly generates and translates subtitles for your content. Ensure your videos capture the global audience they deserve, with captions that cross language barriers.<br />
                        <span style={{ fontStyle: 'italic' }}>No subscriptions, no extra fees.</span>
                    </p>

                    {userMetadata.hasPayed ? (
                        <p style={{ display: 'block', color: 'white', backgroundColor: '#6366F1', width: '100%', marginBottom: '8px', textDecoration: 'none', padding: '8px 16px', borderRadius: '0.375rem', textAlign: 'center' }}>
                            Thank you for purchasing! 🎉
                        </p>
                    )
                        :

                        <div style={{ maxWidth: '100%', margin: 'auto', display: 'flex', justifyContent: 'center' }}>
                            <div>
                                <a
                                    href={sessionURL}
                                    style={{
                                        display: 'block',
                                        color: 'white',
                                        backgroundColor: '#6366F1', // 'bg-indigo-400'
                                        width: '100%',
                                        marginBottom: '8px', // 'mb-2'
                                        textDecoration: 'none',
                                        padding: '8px 16px',
                                        borderRadius: '0.375rem',
                                        textAlign: 'center',
                                    }}
                                >
                                    Buy GeniusSub
                                </a>
                            </div>
                        </div>}
                    <p style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px', fontSize: '0.875rem', textAlign: 'center', color: 'rgb(99, 102, 241)', fontWeight: '500' }}>
                        Pay once, caption endlessly!
                    </p>
                </div>
            </div>
        </Box >
    );


};
