import React, { useState, useEffect, useCallback, useRef } from 'react';
import VideoInput from "../components/VideoInput"
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import StepLabel from '@mui/material/StepLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import LinearProgressWithLabel from '../components/LinearProgressWithLabel'
import { useAuth0 } from "@auth0/auth0-react";
import Tooltip from '@mui/material/Tooltip';
import download from 'downloadjs'
import image from '../assets/videos/vtt_file.webp'
import { Divider } from '@mui/material';


const TranscriptPage = ({ setServerError, setOpen, userMetadata }) => {
    const { isAuthenticated, user, getAccessTokenSilently, isLoading } = useAuth0();
    const theme = useTheme();
    const [uploadedFile, setUploadedFile] = useState(null);
    const [loading, setLoading] = useState(false); //  state pour gérer le chargement
    const [activeStep, setActiveStep] = useState(0);
    const [selectedLanguage, setSelectedLanguage] = useState('en');
    const [vttFile, setVttFile] = useState(null);
    const steps = ['Upload your video', 'Select target language', 'Download your subtitles'];
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // 'sm' pour les écrans "small" et moins
    const [progress, setProgress] = useState(null);
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
    const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL;
    const [ws, setWs] = useState(null);
    const isMdOrLarger = useMediaQuery(theme => theme.breakpoints.up('md'));
    const containerStyle = {
        display: 'flex',
        width: "100%",
        justifyContent: 'center', // centre les éléments horizontalement
        alignItems: 'center',
        flexDirection: isMdOrLarger ? 'row' : 'column'  // Utilisez isMdOrLarger pour définir flexDirection
    };

    const itemStyle = {
        margin: '10px'
    };
    const videoAndImageHeight = isMdOrLarger ? '360px' : '300px';
    const textWidth = isMdOrLarger ? '30%' : '90%';
    useEffect(() => {
        // Établir une connexion WebSocket lors de la montée du composant
        const websocket = new WebSocket(WEBSOCKET_URL);

        websocket.onopen = () => {
            console.log('WebSocket Client Connected');
            // Vous pouvez envoyer un message 'start' ici si nécessaire
            // websocket.send('start');
        };

        websocket.onmessage = (message) => {
            const data = JSON.parse(message.data);
            if (data.progress) {
                setProgress(data.progress);
            }
        };
        websocket.onerror = (error) => {
            console.error("WebSocket Error:", error);
            setOpen(true);
            setServerError(true);
        };
        websocket.onclose = (event) => {
            if (event.wasClean) {
                console.log(`[close] Connection closed cleanly, code=${event.code}, reason=${event.reason}`);
            } else {
                // Par exemple, la connexion serveur a été tuée
                console.error('[close] Connection died');
                setOpen(true);
                setServerError(true);
            }
        };
        setWs(websocket);

        // Fermer la connexion WebSocket lors du démontage du composant
        return () => {
            websocket.close();
        };
    }, []);

    const subtitleCardStyle = {
        width: '300px', // prend en compte l'espacement
        padding: "20px",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '1rem',
        textAlign: 'center',
        fontFamily: 'The Bold Font',
        marginTop: "5px"
    };
    const selectedCardStyle = {
        backgroundColor: theme.palette.primary.dark, // ou toute autre couleur ou style que vous souhaitez appliquer
        ...subtitleCardStyle, // pour conserver les styles existants
    };

    const handleNext = () => {
        // Si ce n'est pas l'étape de mise à jour des sous-titres, passez simplement à la prochaine étape
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleTranscribGeneration = async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('video', uploadedFile);
        formData.append('language', selectedLanguage)
        formData.append('userId', userMetadata.userId)

        if (ws && ws.readyState === WebSocket.OPEN) {
            ws.send('start');
        }
        const token = await getAccessTokenSilently({
            audience: process.env.REACT_APP_API_AUDIENCE,
        });

        // Appel API pour créer une vidéo
        fetch(`${BACKEND_URL}/transcript/`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            body: formData,
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(data => {
                        if (response.status === 403 && data.error) {
                            throw new Error(data.error); // lance l'erreur "Not enough credits"
                        }
                        throw new Error('Server error');
                    });
                }
                return response.json();
            })
            .then(data => {
                if (data && data.filePath) {
                    setVttFile(data.filePath);
                    setLoading(false)
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                const errorMessage = error.error ? error.error : error.message;
                setServerError(errorMessage);
                setOpen(true)
                setLoading(false);
                setProgress(0);
                setActiveStep(0);
                setUploadedFile(null)
                setVttFile(null)
            });
    }

    const cardActionStyle = {
        height: '100%',
        fontFamily: 'The Bold Font',
        textTransform: "uppercase",
        textAlign: "center",
        fontSize: "1.5em",
        textShadow: `0 0 2px #000, 0 0 3px #000, 0 0 4px #000`
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="auto"
            borderRadius={2}
            borderColor="grey.500"
            bgcolor="background.paper"
            boxShadow={3}
            padding={2}
            marginTop={4}
            marginBottom={4}
            width={"100%"}
        >


            <div style={containerStyle}>
                <div style={{ width: textWidth, textAlign: "left", ...itemStyle }}>
                    <Typography
                        variant="h6"
                        gutterBottom
                        color="primary.main"
                        sx={{
                            fontWeight: 'bold',
                        }}
                    >
                        Create and translate subtitles for any video.

                    </Typography>
                    <Typography
                        variant="subtitle1"
                        gutterBottom
                        color="text.primary"

                    >
                        Reach a wider audience and make your content accessible. Download your subtitles as VTT files in just a few clicks.
                    </Typography>
                </div>
                <div style={itemStyle}><img src={image} height={videoAndImageHeight} width={'auto'} /></div>

            </div>

            <Divider sx={{ width: "50%", marginBottom: "25px" }} />

            <Stepper activeStep={activeStep} style={{ marginBottom: "20px" }}>
                {steps.map((label, index) => (
                    <Step key={index}>
                        {isMobile ? (
                            <Tooltip title={label} enterTouchDelay={0} arrow>
                                <StepLabel>{''}</StepLabel>
                            </Tooltip>
                        ) : (
                            <StepLabel>{label}</StepLabel>
                        )}
                    </Step>
                ))}
            </Stepper>
            <React.Fragment>
                {activeStep === 0 && (
                    <VideoInput onFileSelected={(file) => {
                        setUploadedFile(file);
                        handleNext();

                    }} />
                )}
                {activeStep === 1 && (
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                    >
                        <Typography variant="h5" gutterBottom>
                            Please select the target language for your subtitles.
                        </Typography>

                        <Card style={selectedLanguage === 'fr' ? selectedCardStyle : subtitleCardStyle}>

                            <CardActionArea style={cardActionStyle} onClick={() => setSelectedLanguage('fr')}>
                                🇫🇷 French
                            </CardActionArea>
                        </Card>
                        <Card style={selectedLanguage === 'en' ? selectedCardStyle : subtitleCardStyle}>

                            <CardActionArea style={cardActionStyle} onClick={() => setSelectedLanguage('en')}>
                                🇬🇧 English
                            </CardActionArea>
                        </Card>
                        <Card style={selectedLanguage === 'de' ? selectedCardStyle : subtitleCardStyle}>

                            <CardActionArea style={cardActionStyle} onClick={() => setSelectedLanguage('de')}>
                                🇩🇪 German
                            </CardActionArea>
                        </Card>
                        <Card style={selectedLanguage === 'es' ? selectedCardStyle : subtitleCardStyle}>

                            <CardActionArea style={cardActionStyle} onClick={() => setSelectedLanguage('es')}>
                                🇪🇸 Spanish
                            </CardActionArea>
                        </Card>
                        <Card style={selectedLanguage === 'ru' ? selectedCardStyle : subtitleCardStyle}>

                            <CardActionArea style={cardActionStyle} onClick={() => setSelectedLanguage('ru')}>
                                🇷🇺 Russian<span style={{fontSize: "0.7em", color: "#19F5AA", marginLeft:"3px"}}>Beta</span>
                            </CardActionArea>
                        </Card>
                        <Card style={selectedLanguage === 'pt' ? selectedCardStyle : subtitleCardStyle}>

                            <CardActionArea style={cardActionStyle} onClick={() => setSelectedLanguage('pt')}>
                                🇵🇹 Portuguese<span style={{fontSize: "0.7em", color: "#19F5AA", marginLeft:"3px"}}>Beta</span>
                            </CardActionArea>
                        </Card>
                    </Box>)}
                {activeStep === 2 && (
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                    >
                        { loading ? <Box display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center">
                            <Typography variant="h5" gutterBottom>
                                Your video is being processed.
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                Please stay on this page. Your subtitles will be available to download once processed.
                            </Typography>
                            <Box sx={{ width: '100%' }}>
                                <LinearProgressWithLabel value={progress} />
                            </Box>
                        </Box> :
                            <Box display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center">
                                <Button variant="contained" color="primary" style={{ marginTop: "10px" }} onClick={async () => {
                                    const res = await fetch(`${BACKEND_URL}/download/${vttFile}`);
                                    const blob = await res.blob();
                                    download(blob, `GeniusSub.com_${selectedLanguage}.vtt`)
                                }}>
                                    Download VTT file
                                </Button>
                                <Button variant="contained" color="secondary" style={{ marginTop: '10px' }} onClick={() => {
                                    setVttFile(null);
                                    setLoading(false);
                                    setActiveStep(0); // Reset to the first step
                                    setProgress(0);
                                }}>
                                    Process New Video
                                </Button></Box>

                        }
                    </Box>

                )}
                <Box display="flex" justifyContent="space-between" width={{ xs: '100%', md: '80%' }} marginTop={3}>
                    {activeStep !== 0 && !loading && (
                        <Button onClick={handleBack}>
                            Back
                        </Button>
                    )}
                    {activeStep !== steps.length - 1 && activeStep !== 1 && uploadedFile && !loading && (
                        <Button variant="contained" color="primary" onClick={handleNext}>
                            Next
                        </Button>
                    )}
                    {activeStep === 1 && (
                        <Button variant="contained" color="primary" onClick={() => {handleTranscribGeneration(); handleNext()}} >
                            Generate VTT file
                        </Button>
                    )}
                </Box>
            </React.Fragment>

        </Box>
    );
}

export default TranscriptPage;
