import React, { useRef, useState } from 'react';
import { Button, TextField, Box, Typography, InputAdornment } from '@mui/material';
import { styled } from '@mui/system';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useTheme } from '@mui/material/styles';
import languages from '../assets/languages.json';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Switch from '@mui/material/Switch';
import Select from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '1rem',
  marginTop: '1vh',
});

const StyledInput = styled('input')({
  width: 300,
  maxWidth: '100%',
  color: 'white',
  padding: 5,
  background: '#121212',
  borderRadius: 10,
  border: '1px solid #dae4fa',
  '&::file-selector-button': {
    marginRight: 20,
    border: 'none',
    padding: '10px 20px',
    borderRadius: 5,
    color: 'white',
    cursor: 'pointer',
    transition: 'background 0.2s ease-in-out',
  },
  '&.a::file-selector-button': {
    background: '#7b84f4',
    '&:hover': {
      background: '#4b42d0',
    },
  }
});

function VideoInput({
  onFileSelected,
  targetLanguage,
  setTargetLanguage,
  translateEnabled,
  setTranslateEnabled
}) {

  const [file, setFile] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const theme = useTheme();
  const videoRef = useRef();
  const fileInputRef = useRef(); // Add this line
  const FileInput = styled('input')({
    display: 'none',
  });

  const handleChange = (event) => {
    const selectedFile = event.target.files[0];

    // Vérifier le type de fichier
    if (selectedFile.type !== "video/mp4") {
      setSnackbarMessage("Only MP4 files are allowed.");
      setOpenSnackbar(true);
      return;
    }

    if (selectedFile.size > 100 * 1024 * 1024) {
      setSnackbarMessage("File size must not exceed 100 MB.");
      setOpenSnackbar(true);
      return;
    }

    setFile(selectedFile);
  };



  const handleClick = () => {
    if (file) {
      onFileSelected(file)
      if (videoRef.current) {
        videoRef.current.src = URL.createObjectURL(file);
      }
    }
  };

  return (
    <Box style={{ alignItem: "center", textAlign: "left", margin: '10px' }}>
      <Typography
        variant="subtitle1"
        gutterBottom
        sx={{
          color: 'white',
          textAlign: "left",
          marginLeft: "10px",
          fontSize: '1.2rem',
        }}
      >
        Upload your video
      </Typography>
      <div style={{ marginLeft: "15px" }}>
        <Typography variant="body" color="text.secondary" style={{ margin: '10px' }}>
          Format supported: MP4
        </Typography>
        <Typography variant="body2" color="text.secondary" style={{ margin: '10px' }}>
          Maximum file size: 100MB
        </Typography>
      </div>
      <Container>
        <StyledInput type="file" className="a" onChange={handleChange} />

        <Button
          variant="contained"
          onClick={handleClick}
          style={{
            background: '#7b84f4',
            color: 'white',
            padding: '10px 20px',
            borderRadius: 5,
            cursor: 'pointer',
            transition: 'background 0.2s ease-in-out',
            '&:hover': {
              background: '#4fffc5',
            },
          }}
        > Upload </Button>
      </Container>
      <div style={{
        marginTop: "15px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        background: "#121212",
        width: "100%",
        borderRadius: "15px",
        padding: "10px"
      }}>
        <div style={{ width: "80%" }}>
          <Typography variant="body1" color="#7b84f4" style={{ display: "flex", alignSelf: "flex-start" }}>
            BETA
          </Typography>
          <FormGroup style={{ marginLeft: "auto", alignItems: 'center', marginBottom: '15px' }}>
            <FormControlLabel
              control={
                <Switch
                  checked={translateEnabled}
                  onChange={() => setTranslateEnabled(!translateEnabled)}
                />
              }
              label="Translate captions"
            />
          </FormGroup>
          <FormHelperText>Enable translation of your captions into a desired language.</FormHelperText>
        </div>
        {translateEnabled && (
          <FormControl fullWidth style={{ marginTop: "15px" }}>
            <Select
              value={targetLanguage}
              onChange={(e) => setTargetLanguage(e.target.value)}
            >
              <MenuItem value="input">Target Language</MenuItem>
              {languages.languages.map(lang => (
                <MenuItem key={lang} value={lang}>{lang}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

      </div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="error">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default VideoInput;