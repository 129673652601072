import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from '@mui/material/Button';

const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();

    return (
        <div style={{ margin: "1rem" }}>
            <Button
                style={{
                    display: "inline-block",
                    padding: "0.5rem 1rem",
                    backgroundColor: "#7b84f4",
                    color: "#FFF",
                    textDecoration: "none",
                    borderRadius: "0.25rem",
                    fontWeight: "bold",
                    cursor: "pointer",
                    marginRight: "0.5rem",
                }}
                onClick={() => {
                    loginWithRedirect({
                        audience: process.env.REACT_APP_API_AUDIENCE,
                    });
                }}
            >
                Login
            </Button>
            <Button
                style={{
                    display: "inline-block",
                    padding: "0.5rem 1rem",
                    backgroundColor: "#443acb",
                    color: "#FFF",
                    textDecoration: "none",
                    borderRadius: "0.25rem",
                    fontWeight: "bold",
                    cursor: "pointer",
                }}
                onClick={() => {
                    loginWithRedirect({
                        audience: process.env.REACT_APP_API_AUDIENCE,
                    });
                }}
            >
                sign up
            </Button>
        </div>
    );
}
export default LoginButton;