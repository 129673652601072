import React, { useState, useEffect, useCallback, useRef } from 'react';
import Box from '@mui/material/Box';
import VideoPreview from './VideoPreview'
import SubTypeSelector from './SubTypeSelector';
import SubOptions from './SubOptions';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Divider } from '@mui/material';
export default function SubtitleStyler({
    selectedStyle,

    selectHighlight,
    onSelectHighlight,
    subPosition,
    onChangeSubPosition,
    onSelectStyle,
    videoPreviewUrl,
    subtitles,
    targetLanguage,
    setTargetLanguage,
    subSize,
    onChangeSubSize
}) {
    const isMdOrLarger = useMediaQuery(theme => theme.breakpoints.up('md'));

    return (
        <>
            <Box style={{
                borderRadius: "15px",
                overflow: "hidden",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
                flexDirection: isMdOrLarger ? 'row' : 'column-reverse', // Utiliser 'column-reverse' pour les mobiles
                padding: "20px"
            }}>

                <div style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column"
                }}>

                    <Divider sx={{ width: "80%", marginBottom: "25px", marginTop: "25px" }} textAlign="center">CAPTION OPTIONS</Divider>

                    <SubOptions

                        selectHighlight={selectHighlight}
                        onSelectHighlight={onSelectHighlight}
                        onChangeSubPosition={onChangeSubPosition}
                        subPosition={subPosition}
                        targetLanguage={targetLanguage}
                        setTargetLanguage={setTargetLanguage}
                        subSize={subSize}
                        onChangeSubSize={onChangeSubSize}
                    />

                    <Divider sx={{ width: "80%", marginBottom: "25px" }} textAlign="center">CAPTION STYLE</Divider>

                    <SubTypeSelector
                        selectedStyle={selectedStyle}
                        onSelectStyle={onSelectStyle}
                    />
                </div>

                <div style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: isMdOrLarger ? 0 : "20px"
                }}>
                    <VideoPreview
                        subPosition={subPosition}
                        subStyleSelected={selectedStyle}
                        selectHighlight={selectHighlight}
                        videoPreviewUrl={videoPreviewUrl}
                        subtitles={subtitles}
                        targetLanguage={targetLanguage}
                        subSize={subSize}
                    />
                </div>

            </Box>
        </>
    );


}
