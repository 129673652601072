import React from "react";
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Slider from '@mui/material/Slider'
import { Typography } from "@mui/material";
import Divider from "@mui/material";
const SubOptions = ({
  selectHighlight,
  onSelectHighlight,
  onChangeSubPosition,
  onChangeSubSize,
  subPosition,
  subSize
}) => {

  return (
    <div>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
      }}>

        <div style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: 'center',
          alignContent: "center"
        }}>
          <div style={{ marginTop: "5px", width: "100%" }} >
            <FormControl style={{ display: "block", flexDirection: "row", width: "100%", justifyContent: "space-between" }}>
              <Typography
                variant="subtitle1"
                gutterBottom
                color="text.primary"

              >
                Vertical Position
              </Typography>
              <Slider min={15} max={90} value={subPosition} aria-label="Default" valueLabelDisplay="auto" valueLabelFormat={val => `${val}%`} onChange={e => onChangeSubPosition(e.target.value)} />
            </FormControl>
          </div>
        </div>
        <div style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: 'center',
          alignContent: "center"
        }}>
          <div style={{ marginTop: "15px", width: "100%" }} >
            <FormControl style={{ display: "block", flexDirection: "row", width: "100%", justifyContent: "space-between" }}>
              <Typography
                variant="subtitle1"
                gutterBottom
                color="text.primary"

              >
                Size
              </Typography>
              <Slider min={60} max={90} value={subSize} aria-label="Default" valueLabelDisplay="auto" valueLabelFormat={val => `${val}px`} onChange={e => onChangeSubSize(e.target.value)} />
            </FormControl>
          </div>
        </div>
        <div style={{ width: "auto" }}>
          <FormGroup>
            <div style={{ display: 'flex', alignItems: 'center', maxWidth: "250px" }}>
              <FormControlLabel
                control={<Switch />}
                label="Apply Color Highlights to Subtitles"
                sx={{ width: '300px' }}
                checked={selectHighlight}
                onChange={(event) => onSelectHighlight(event.target.checked)}
              />
            </div>
          </FormGroup>
        </div>
      </div>
    </div>
  );
}

export default SubOptions;