import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import DOMPurify from 'dompurify';
const SubtitleItem = React.memo(({ subtitle, onUpdate }) => {
  const [text, setText] = useState(DOMPurify.sanitize(subtitle.transcription));
  const handleTextChange = (e) => {
    setText(DOMPurify.sanitize(e.target.value));
  };

  const handleBlur = () => {
    onUpdate(text);  // Utilisez l'ID si disponible, sinon utilisez la transcription
    };

  return (
    <div style={{ padding: "3px" }}>
      <TextField
        fullWidth
        value={text}
        onChange={handleTextChange}
        onBlur={handleBlur}
        multiline
        maxRows={3}
        key={subtitle.id || subtitle.transcription}
      />
    </div>
  );
});

const SubtitleEditor = React.memo(({ subtitles: initialSubtitles, onSubtitleUpdate }) => {
  const [localSubtitles, setLocalSubtitles] = useState(initialSubtitles); // Copie locale des sous-titres
  
  const handleUpdate = (subtitleIndex, newText) => {
    const updatedSubtitles = [...localSubtitles];
    const modifiedWords = newText.split(' ');
    updatedSubtitles[subtitleIndex].transcription = newText;

    // Determine time delta between words for the subtitle
    const deltaTime = (updatedSubtitles[subtitleIndex].time_end - updatedSubtitles[subtitleIndex].time_begin) / modifiedWords.length;

    const newWords = modifiedWords.map((word, index) => {
        if (index < updatedSubtitles[subtitleIndex].words.length) {
            // Update the existing word
            return {
                ...updatedSubtitles[subtitleIndex].words[index],
                word
            };
        } else {
            // Add the new word with approximated timing
            return {
                word,
                time_begin: updatedSubtitles[subtitleIndex].time_begin + index * deltaTime,
                time_end: updatedSubtitles[subtitleIndex].time_begin + (index + 1) * deltaTime,
                confidence: 0.5  // Setting a default confidence; you might want to adjust this
            };
        }
    });

    updatedSubtitles[subtitleIndex].words = newWords;

    setLocalSubtitles(updatedSubtitles);
    onSubtitleUpdate(updatedSubtitles);  // Notify the parent component of the updated subtitles
};

  


  return (
    <Box className="subtitlesContainer" style={{
      maxHeight: '400px',  // vous pouvez ajuster cette hauteur selon vos besoins
      overflowY: 'scroll',
      padding: '10px',
      width: "100%"
    }}>
      {localSubtitles.map((subtitle, index) => (
  <SubtitleItem 
    key={index} 
    subtitle={subtitle} 
    subtitleIndex={index}  // Pass the current index
    onUpdate={(newText) => handleUpdate(index, newText)}
  />
))}
    </Box>
  );
});

export default SubtitleEditor;