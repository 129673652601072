import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import TheBoldFont from './assets/fonts/THEBOLDFONT.ttf'
import Comfortaa from './assets/fonts/Comfortaa-Light.ttf'
import Poppins from './assets/fonts/Poppins-Medium.ttf'
import Montserrat from './assets/fonts/HelveticaNeueMedium.otf'

// A custom theme for this app
const theme = createTheme({
  palette: {
    mode: 'dark', // This is the line you need to change to 'dark'
    primary: {
      light: '#9aa3fc',
      main: '#818cf8', // Turquoise
      dark: '#483ece',
    },
    secondary: {
      light: '#FFAD9E',
      main: '#FF7043', // Rose
      dark: '#FF3D00',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#262320",
      paper: "#121212",
    },
    text: {
      primary: "#fff",
      secondary: "#888"
    }
  },
  typography: {
    fontFamily: 'Poppins, Roboto, Helvetica Neue, Arial, sans-serif, Comfortaa, The Bold Font',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'The Bold Font';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('THEBOLDFONT'), local('THEBOLDFONT'), url(${TheBoldFont}) format('truetype');
        }
        @font-face {
          font-family: 'Comfortaa';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Comfortaa-Light'), local('Comfortaa-Light'), url(${Comfortaa}) format('truetype');
        }
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Poppins-Medium'), local('Poppins-Medium'), url(${Poppins}) format('truetype');
        }
        @font-face {
          font-family: 'Helvetica Neue';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('HelveticaNeueMedium'), local('HelveticaNeueMedium'), url(${Montserrat}) format('truetype');
        }
        @font-face {
          font-family: "Noto Color Emoji";
          src: url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&family=Noto+Color+Emoji&display=swap');
          font-weight: normal;
          font-style: normal;
        }
      `,
    },
  }, '@global': {
    '*::-webkit-scrollbar': {
      width: '0.4em'
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey'
    }
  }
});

export default theme;