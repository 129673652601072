import React, { useState, useEffect, useCallback, useRef } from 'react';
import Box from '@mui/material/Box';
import { useAuth0 } from "@auth0/auth0-react";
import VideoInput from "../components/VideoInput"
import CircularProgress from '@mui/material/CircularProgress';
import Button from "@mui/material/Button"
import SubTypeSelector from '../components/SubTypeSelector';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import LinearProgressWithLabel from '../components/LinearProgressWithLabel'
import SubOptions from "../components/SubOptions"
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Tooltip from '@mui/material/Tooltip';
import SubtitleEditor from '../components/SubtitleEditor';
import download from 'downloadjs'
import video from '../assets/videos/videosub.mp4'
import { Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import SubtitleStyler from '../components/SubtitleStyler'
import { Link } from 'react-router-dom';
export default function AutoSubPage({ setServerError, setOpen, userMetadata }) {

  const { isAuthenticated, user, getAccessTokenSilently, isLoading } = useAuth0();
  const [loading, setLoading] = useState(false); //  state pour gérer le chargement
  const [uploading, setUploading] = useState(false); //  state pour gérer le chargement
  const [videoUrl, setVideoUrl] = useState(null);
  const [highlightSub, setHighlightSub] = useState(false);
  const [subPosition, setSubPosition] = useState(30);
  const [subSize, setSubSize] = useState(60);

  const [targetLanguage, setTargetLanguage] = useState("input");
  const [translateEnabled, setTranslateEnabled] = useState(false);

  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL;
  const [selectedSubtitleStyle, setSelectedSubtitleStyle] = useState('trendy1');
  const [activeStep, setActiveStep] = useState(0);
  const steps = ['Upload your video', 'Review your subtitles', 'Select captions style', 'Download video'];
  const [uploadedFile, setUploadedFile] = useState(null); // Nouvelle variable d'état pour stocker le fichier vidéo temporairement
  const [progress, setProgress] = useState(null);
  const [ws, setWs] = useState(null);
  const [subtitles, setSubtitles] = useState([]);
  const [videoId, setVideoId] = useState([]);
  const [videoPreviewUrl, setVideoPreviewUrl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // 'sm' pour les écrans "small" et moins
  const subtitlesRef = useRef([]);
  const isMdOrLarger = useMediaQuery(theme => theme.breakpoints.up('md'));
  const containerStyle = {
    display: 'flex',
    width: "100%",
    marginBottom: "10px",
    justifyContent: 'center', // centre les éléments horizontalement
    alignItems: 'center',
    flexDirection: isMdOrLarger ? 'row' : 'column'  // Utilisez isMdOrLarger pour définir flexDirection
  };
  const itemStyle = {
    margin: '10px'
  };
  const textWidth = isMdOrLarger ? '50%' : '90%';
  useEffect(() => {
    // Établir une connexion WebSocket lors de la montée du composant
    const websocket = new WebSocket(WEBSOCKET_URL);

    websocket.onopen = () => {
      console.log('WebSocket Client Connected');
      // Vous pouvez envoyer un message 'start' ici si nécessaire
      // websocket.send('start');
    };

    websocket.onmessage = (message) => {
      const data = JSON.parse(message.data);
      if (data.progress) {
        setProgress(data.progress);
      }
    };
    websocket.onerror = (error) => {
      console.error("WebSocket Error:", error);
      setOpen(true);
      setServerError(true);
    };
    websocket.onclose = (event) => {
      if (event.wasClean) {
        console.log(`[close] Connection closed cleanly, code=${event.code}, reason=${event.reason}`);
      } else {
        // Par exemple, la connexion serveur a été tuée
        console.error('[close] Connection died');
        setOpen(true);
        setServerError(true);
      }
    };
    setWs(websocket);

    // Fermer la connexion WebSocket lors du démontage du composant
    return () => {
      websocket.close();
    };
  }, []);

  useEffect(() => {
    subtitlesRef.current = subtitles;
  }, [subtitles]);

  const handleVideoSubmit = async (file) => {
    // Déclencher le spinner de chargement
    setLoading(true);
    setProgress(0)

    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_API_AUDIENCE,
    });
    // Appel API pour créer une vidéo
    fetch(`${BACKEND_URL}/videos/addSub?type=${selectedSubtitleStyle}&highlightSub=${highlightSub}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json' // Ajoutez cet en-tête
      },
      body: JSON.stringify({
        subtitles: subtitles,
        videoId: videoId,
        userId: userMetadata.userId,
        subPosition: subPosition,
        subSize: subSize
      }),
    })
      .then(response => {
        if (!response.ok) {
          return response.json().then(err => { throw err; });
        }
        return response.json();
      })

      .then(data => {
        if (data.error) {
          //throw new Error(data.error);
        }
        setVideoUrl(data.link)
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error:', error);
        const errorMessage = error.error ? error.error : error.message;
        setServerError(errorMessage);
        setLoading(false);
        setOpen(true);
        setActiveStep(0)
        setProgress(null)
        setSubtitles([])
      });
  };

  const handleSubGeneration = async (file) => {
    setUploading(true);
    setLoading(true)
    const formData = new FormData();
    formData.append('file', file);
    formData.append('userId', userMetadata.userId)
    if (translateEnabled) {
      formData.append('targetLanguage', targetLanguage)
    }
    handleVideoSelection(file)
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send('start');
    }
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_API_AUDIENCE,
    });

    // Appel API pour créer une vidéo
    fetch(`${BACKEND_URL}/videos/generateSub`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      body: formData,
    })
      .then(response => {
        if (!response.ok) {
          return response.json().then(err => { throw err; });
        }
        return response.json();
      })

      .then(data => {
        if (data.error) {
          //throw new Error(data.error);
        }
        setSubtitles(data.subtitles);
        setVideoId(data.video)
        setUploading(false);
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error:', error);
        const errorMessage = error.error ? error.error : error.message;
        setServerError(errorMessage);
        setLoading(false);
        setProgress(null);
        setOpen(true);
        setActiveStep(0)
        setSubtitles([])
      });
  }
  const handleNext = () => {
    if (activeStep === 1) {  // Si l'utilisateur est à l'étape de mise à jour des sous-titres
      setSubtitles(subtitlesRef.current); // Récupérez les sous-titres mis à jour
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
    else {
      // Si ce n'est pas l'étape de mise à jour des sous-titres, passez simplement à la prochaine étape
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    ;
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleVideoSelection = (file) => {
    const objectUrl = URL.createObjectURL(file);
    setVideoPreviewUrl(objectUrl);
  };

  if (isLoading || !userMetadata) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="30vh"
        borderRadius={2}
        borderColor="grey.500"
        bgcolor="background.paper"
        boxShadow={3}
        padding={4}
        marginTop={4}
        marginBottom={4}
      >
        <CircularProgress />
      </Box>
    );
  }


  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="auto"
      borderRadius={2}
      borderColor="grey.500"
      bgcolor="#121212"
      boxShadow={3}
      padding={2}
      marginTop={4}
      marginBottom={4}
      width={"100%"}
    >

      {activeStep === 0 && <><div style={containerStyle}>
        <div style={{ width: textWidth, textAlign: "left", ...itemStyle }}>
          <Typography
            variant="h6"
            gutterBottom
            color="text.primary"
            sx={{ fontSize: "1.1em" }}
          >
            Say good bye to editing nightmare and focus on content!
          </Typography>
          <Typography
            variant="subtitle2"
            gutterBottom
            color="text.primary"
          >
            Our tool auto-generates, translate and embeds captions for you.
          </Typography>


        </div>
        { /*Badge qui affiche le nombre de crédit restant ou Premium si l'utilisateur hasPayed === true */}

        {/* <div style={{ marginLeft: "15px", borderRadius: "15px", width: "202px", height: "360px", overflow: "hidden" }}>
          <video autoPlay muted loop width="202px" height="360px">
            <source src={video} type="video/mp4" />                Votre navigateur ne supporte pas les vidéos HTML5.
          </video>
          </div>*/}

      </div>
        <Divider sx={{ width: "50%", marginBottom: "25px", marginTop: "15px" }} /></>
      }

      {/*Aligner la Div et son contenu à gauche*/}

      {userMetadata.hasPayed === false && userMetadata.credits === 0 ? (
        <div>
          <Typography variant="subtitle2" gutterBottom color="text.primary" sx={{ textAlign: "center" }}>

            You've reached your 5 free credits plan. <br />
            Please purchase the product to access GeniusSub Editor.
          </Typography>
          <div style={{ maxWidth: '100%', margin: 'auto', display: 'flex', justifyContent: 'center' }}>
            <div>
              <Link
                to='/pricing'
                style={{
                  display: 'block',
                  color: 'white',
                  backgroundColor: '#6366F1', // 'bg-indigo-400'
                  width: '100%',
                  marginBottom: '8px', // 'mb-2'
                  textDecoration: 'none',
                  padding: '8px 16px',
                  borderRadius: '0.375rem',
                  textAlign: 'center',
                }}
              >
                Get GeniusSub
              </Link>
            </div>
          </div>
        </div>

      ) : (
        <div>
          <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%', marginBottom: '20px' }}>
            {!userMetadata.hasPayed ? <button
              style={{
                backgroundColor: '#f0fdf4',  // Equivalent of bg-green-100
                color: '#065f46',             // Equivalent of text-green-800
                fontSize: '0.75rem',          // Equivalent of text-xs
                fontWeight: '600',            // Equivalent of font-semibold
                padding: '2px 10px',          // Equivalent of px-2.5 py-0.5
                borderRadius: '0.375rem',     // Equivalent of rounded
                border: 'none',
                cursor: 'not-allowed'
              }}
              disabled>
              Free plan: {userMetadata.credits} credits remaining
            </button> :
              <button
                style={{
                  backgroundColor: '#f0fdf4',  // Equivalent of bg-green-100
                  color: '#065f46',             // Equivalent of text-green-800
                  fontSize: '0.75rem',          // Equivalent of text-xs
                  fontWeight: '600',            // Equivalent of font-semibold
                  padding: '2px 10px',          // Equivalent of px-2.5 py-0.5
                  borderRadius: '0.375rem',     // Equivalent of rounded
                  border: 'none',
                  cursor: 'not-allowed'
                }}
                disabled>
                Premium
              </button>}
          </div>
          <Stepper activeStep={activeStep} style={{ marginBottom: "20px" }}>

            {steps.map((label, index) => (
              <Step key={index}>
                {isMobile ? (
                  <Tooltip title={label} enterTouchDelay={0} arrow>
                    <StepLabel>{''}</StepLabel>
                  </Tooltip>
                ) : (
                  <StepLabel>{label}</StepLabel>
                )}
              </Step>
            ))}
          </Stepper>
        </div>
      )
      }
      <React.Fragment>

        {activeStep === 0 && (userMetadata.hasPayed === true || userMetadata.credits > 0) && (
          <Box width={{ xs: '100%', md: '80%' }} margin="0 auto" display="flex" flexDirection="column" alignItems="center">
            <VideoInput
              targetLanguage={targetLanguage}
              setTargetLanguage={setTargetLanguage}
              translateEnabled={translateEnabled}
              setTranslateEnabled={setTranslateEnabled}
              onFileSelected={(file) => {
                setUploadedFile(file);
                handleSubGeneration(file)
                handleNext();

              }} />

          </Box>
        )}
        {activeStep === 1 && (
          <Box
            width={{ xs: '100%', md: '60%' }}
            margin="0 auto"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100%" // ou une hauteur fixe si vous le souhaitez
          >
            {uploading ?
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="h5" gutterBottom>
                  Your captions are being generated.
                </Typography>
                <CircularProgress />
              </Box>
              :
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                width="100%"
              >
                <Typography variant="h5" gutterBottom>
                  Review and modify your captions.
                </Typography>
                <SubtitleEditor
                  subtitles={subtitles}
                  onSubtitleUpdate={(updatedSubtitles) => {
                    subtitlesRef.current = updatedSubtitles;  // Mettre à jour la référence avec les sous-titres mis à jour
                  }}
                />
              </Box>}
          </Box>
        )}
        {activeStep === 2 && (
          <Box
            width={{ xs: '100%', md: '70%' }}
            margin="0 auto"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100%" // ou une hauteur fixe si vous le souhaitez
          >
            <Typography variant="h5" gutterBottom>
              Select your captions style.
            </Typography>
            <SubtitleStyler
              selectedStyle={selectedSubtitleStyle}
              onSelectStyle={(style) => {
                setSelectedSubtitleStyle(style);
              }}
              selectHighlight={highlightSub}
              onSelectHighlight={(highlight) => {
                setHighlightSub(highlight)
              }}
              subPosition={subPosition}
              onChangeSubPosition={(position) => {
                setSubPosition(position)
              }}
              videoPreviewUrl={videoPreviewUrl}
              subtitles={subtitles}
              subSize={subSize}
              onChangeSubSize={(size) => {
                setSubSize(size)
              }} />

          </Box>
        )}

        {/*activeStep === 2 && (
          <Box width={{ xs: '100%', md: '80%' }}>
            <SubTypeSelector
              selectedStyle={selectedSubtitleStyle}
              onSelectStyle={(style) => {
                setSelectedSubtitleStyle(style);
              }}
            />
          </Box>
        )}

        {activeStep === 3 && (
          <Box width={{ xs: '100%', md: '80%' }}>
            <SubOptions

              selectedEmoji={useEmoji}
              onSelectEmoji={(emoji) => {
                setUseEmoji(emoji)
              }}
              selectHighlight={highlightSub}
              onSelectHighlight={(highlight) => {
                setHighlightSub(highlight)
              }}
              subPosition={subPosition}
              onChangeSubPosition={(position) => {
                setSubPosition(position)
              }}
            />
          </Box>
            )*/}
        {activeStep === 3 && (
          <Box display="flex" flexDirection="column" alignItems="center">
            {loading ?
              <Box display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center">
                <Typography variant="h5" gutterBottom>
                  Your video is being processed.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Please stay on this page. Your video will be available to download once processed.
                </Typography>
                <Box sx={{ width: '100%' }}>
                  <LinearProgressWithLabel value={progress} />
                </Box>                </Box> :
              videoUrl && !loading &&
              <Box display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center">

                <Button variant="contained" color="primary" style={{ color: "white" }} onClick={async () => {
                  const res = await fetch(`${BACKEND_URL}/download/${videoUrl}`);
                  const blob = await res.blob();
                  download(blob, "GeniusSub.com_video_sub.mp4")
                }}>Download Video</Button>
                <Button variant="contained" style={{ marginTop: '10px', backgroundColor: theme.palette.primary.dark, color: "white" }} onClick={() => {
                  setVideoUrl(null);
                  setLoading(false);
                  setActiveStep(0); // Reset to the first step
                  setHighlightSub(false);
                  setSelectedSubtitleStyle('trendy1')
                  setSubtitles([])
                  setProgress(null);
                  setSubPosition(30);
                  setSubSize(60)
                }}>
                  Process New Video
                </Button></Box>
            }
          </Box>
        )}

        <Box display="flex" justifyContent="space-between" width={{ xs: '100%', md: '80%' }} marginTop={3}>
          {activeStep !== 0 && !loading && (
            <Button onClick={handleBack}>
              Back
            </Button>
          )}
          {activeStep !== steps.length - 1 && activeStep !== 2 && uploadedFile && !loading && (
            <Button variant="contained" color="primary" onClick={handleNext}>
              Next
            </Button>
          )}
          {activeStep === 2 && (
            <Button variant="contained" color="primary" onClick={() => { handleVideoSubmit(uploadedFile); handleNext() }} style={{ marginTop: "10px" }}>
              Generate
            </Button>
          )}
        </Box>
      </React.Fragment>
    </Box>
  );
};
