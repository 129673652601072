import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import theme from './theme';
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const AuthHandler = () => {
  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [userMetadata, setUserMetadata] = React.useState(null); // Déplacé à l'intérieur du composant
  React.useEffect(() => {
    const createUser = async () => {
      if (isAuthenticated && user) {
        // Check if user already exists before sending request

        const token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_API_AUDIENCE,
        });

        try {
          const response = await fetch(`${BACKEND_URL}/users/createUser`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(user),
          });

          if (!response.ok) {
            throw new Error('Server error');
          }
          const data = await response.json(); // Supposons que votre API renvoie des données JSON
          setUserMetadata({ userId: user.sub, ...data.app_metadata }); // Étape 2: mise à jour de la variable d'état avec la valeur renvoyée
          console.log(userMetadata)
        } catch (error) {
          // Handle error
          console.error(error);

        }
      }
    }
    createUser();
    const intervalId = setInterval(createUser, 30000); // Interroger toutes les 5 secondes
    return () => clearInterval(intervalId); // Nettoyer l'intervalle lorsque le composant est démonté
  }, [isAuthenticated, getAccessTokenSilently, user]);

  return <App userMetadata={userMetadata} />;
}


const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(

  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: "https://app.geniussub.com/",
      audience: process.env.REACT_APP_API_AUDIENCE

    }}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthHandler />
    </ThemeProvider>
  </Auth0Provider >
);
