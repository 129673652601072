import React, { useState, useEffect, useCallback, useRef } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import NavBar from './components/NavBar'
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from "./components/LoginButton"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AutoSubPage from './views/AutoSubPage';
import TranscriptPage from './views/TranscriptPage'; // Importez le nouveau composant
import Pricing from './views/Pricing';
export default function App({ userMetadata }) {

  const { isAuthenticated, user, getAccessTokenSilently, isLoading } = useAuth0();
  const [serverError, setServerError] = useState(""); // Add this line
  const [open, setOpen] = useState(false);

  if (!isAuthenticated) {
    return (
      <Box>
        <Router>
          <NavBar isUserAuthenticated={isAuthenticated} />
          <Container maxWidth="lg">
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height="30vh"
              borderRadius={2}
              borderColor="gray.100"
              bgcolor="background.paper"
              padding={4}
              marginTop={4}
              marginBottom={4}
            >
              <img src={"logo.png"} style={{ height: "60px", padding: "5px" }} />
              <p style={{ fontSize: "1em", marginTop: "0px" }}>
                Sign up or Login
              </p>
              <LoginButton />
            </Box>
          </Container>
        </Router >
        <Snackbar open={open && serverError !== ""} autoHideDuration={6000} onClose={() => setOpen(false)}>
          <Alert onClose={() => setOpen(false)} severity="error" sx={{ width: '100%' }}>
            {serverError}
          </Alert>
        </Snackbar>
      </Box>
    );
  }

  return (
    <Box>
      <Router>
        <NavBar userMetadata={userMetadata} />
        <Container maxWidth="lg">
          <Routes>
            <Route path="/" element={<AutoSubPage setServerError={setServerError} setOpen={setOpen} userMetadata={userMetadata} />} />
            <Route path="/transcript" element={<TranscriptPage setServerError={setServerError} setOpen={setOpen} userMetadata={userMetadata} />} />
            <Route path="/pricing" element={<Pricing userMetadata={userMetadata} />} />

          </Routes>
        </Container>
      </Router >
      <Snackbar open={open && serverError !== ""} autoHideDuration={6000} onClose={() => setOpen(false)}>
        <Alert onClose={() => setOpen(false)} severity="error" sx={{ width: '100%' }}>
          {serverError}
        </Alert>
      </Snackbar>
    </Box>
  );
}

