import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { useAuth0 } from '@auth0/auth0-react';
import LoginButton from "./LoginButton"
import { Link } from 'react-router-dom'; // ajout de Link pour les liens
const pages = ['Dashboard', 'My exports', 'Subtitle tool'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

function NavBar({ userMetadata }) {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md')); // 'sm' pour les écrans "small" et moins
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <AppBar position="static" sx={{ backgroundColor: "transparent", border: "none", boxShadow: "none" }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Link to="/" rel="noreferrer">
                        <img src={"logo.png"} style={{ height: "40px", padding: "5px" }} />
                    </Link>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {!isMobile && isAuthenticated && (
                            <>
                                <Button
                                    key={"Editor"}
                                    variant="text"
                                    onClick={handleCloseNavMenu}
                                    component={Link}
                                    to={`/`}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                >
                                    {"Editor"}
                                </Button>
                                <Button
                                    key={"Pricing"}
                                    variant="text"
                                    onClick={handleCloseNavMenu}
                                    component={Link}
                                    to={`/pricing`}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                >
                                    {"Pricing"}
                                </Button>
                            </>
                        )}
                    </Box>
                    {isAuthenticated && userMetadata !== null && !isMobile ? (
                        <>
                            <Tooltip title="Open settings" sx={{ flexGrow: 1, marginRight: "0px" }}>
                                <IconButton onClick={handleOpenUserMenu} sx={{ marginLeft: "auto" }}>
                                    <Avatar alt={user.name} src={user.picture} />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                <MenuItem onClick={() => logout({ returnTo: window.location.origin })}>
                                    <Typography textAlign="center">Logout</Typography>
                                </MenuItem>
                            </Menu>
                        </>
                    ) : isMobile ? (
                        ""
                    ) : (
                        <LoginButton />
                    )}
                    {isMobile && (
                        <IconButton
                            size="large"
                            aria-label="menu"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                            sx={{ marginLeft: 'auto' }}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                    >
                        {isAuthenticated ? (
                            [
                                <MenuItem key="subtitle" onClick={handleCloseNavMenu} component={Link} to="/">
                                    Editor
                                </MenuItem>,
                                <MenuItem
                                    key="pricing"
                                    onClick={handleCloseNavMenu}
                                    component={Link}
                                    to="/pricing"
                                >
                                    Pricing
                                </MenuItem>
                            ]
                        ) : (
                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                width="100%"
                            >
                                <LoginButton />
                            </Box>
                        )}
                    </Menu>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default NavBar;
